body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fa-sort:before {
  content: "\25B4" !important;
  background-color: white;
}

.fa-sort-asc:before {
  content: "\25B4";
  background-color: white;
}

.fa-sort-desc:before {
  content: "\25BE";
  background-color: white;
}

.filterArrows {
  float: right;
}

.load-more-button {
  appearance: none;
    border: 0px;
    background: rgb(29, 114, 216);
    font-size: 1.5rem;
    cursor: pointer;
    line-height: 1rem;
    font-family: Montserrat, sans-serif;
    border-radius: 10rem;
    min-height: 4rem;
    min-width: 4rem;
    padding: 1rem 3rem;
    width: 100%;
    max-width: 25rem;
    color: rgb(255, 255, 255);
}
